import React, { useRef, useEffect } from 'react';
import {Helmet} from "react-helmet";
import * as typeformEmbed from "@typeform/embed"
import { css } from "@emotion/core"

export default function ContactForm() {
  const typeformRef = useRef(null);
  
  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      'https://form.typeform.com/to/E66tMW79', 
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
      });
  }, [typeformRef]);
  
  return (
    <div
    ref={typeformRef}
    id="contact-form" 
    css={css` height: 400px; width: 100%; background-color: white; `}
  >
    <Helmet>
      <script src="https://embed.typeform.com/embed.js" />
    </Helmet>
  </div>
  );
}
