import React from "react"
import linkStyles from "./links.module.scss"
import { css } from "@emotion/core"
import { OutboundLink } from "gatsby-plugin-google-gtag"

class ProjectBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    console.log(linkStyles);
    return (
      <div className="row">
        <div className="col-lg-4 col-sm-12 pr-3 proj-details">
          <h2 css={css` padding: 0; `}>{this.props.title}</h2>
          <p>{this.props.description}</p>
          
          <div className={linkStyles.links__group}>
            <OutboundLink
              href={this.props.liveUrl}
              className={linkStyles.btn} 
              target="_blank" 
              rel="noopener noreferrer"
            >See it live</OutboundLink>

            <OutboundLink
              href={this.props.sourceUrl}
              className={[linkStyles.links__inline].join(' ')} 
              target="_self" 
              rel="noopener noreferrer"
            >Source code</OutboundLink>
          </div>
        </div>
        <div className="col-lg-8 col-sm-12 proj-img">
          <img src={this.props.imageUrl} alt={this.props.imageAlt || "project image"}/>
        </div>
      </div>
    );
  }
}

export default ProjectBlock;
