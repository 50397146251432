import React from "react";
import ProjectBlock from "./project_block"
import Container from "./container"
import Heading from "./heading"

export default function ProjectSection() {
  return (

  <section id="projects">
    <Container>
      <div className="project-wrapper vertical-padding">

      <Heading headerText="Projects" classNames="text-center" />

      <ProjectBlock
        title="Warrior Woman Lane"
        description="Warrior Woman Lane is a responsive static web project built to honor the late Lisa Bellear, a passionate advocate for Aboriginal rights and representation, broadcaster, photographer and poet. This project adopts horizontal scrolling of 3D Folded Layout (https://github.com/Anemolo/FoldingDOM) by team Codrops."
        liveUrl="https://warriorwomanlane.com"
        sourceUrl="#"
        imageUrl="https://user-images.githubusercontent.com/44339322/98538577-1167d180-22df-11eb-84ab-f29c589f9329.png"
      ></ProjectBlock>

      <ProjectBlock
        title="Lanshin.com"
        description="Lanshin is a responsive static web project for Healing by Lanshin studio, built on Squarespace."
        liveUrl="https://lanshin.com"
        sourceUrl="#"
        imageUrl="https://user-images.githubusercontent.com/44339322/100353633-ec938e00-3042-11eb-926f-c8cb0a6e3d9b.png"
      ></ProjectBlock>

      <ProjectBlock
        title="Cuppe.com.au"
        description="Cuppé is a Shopify web store selling reusable, environmentally friendly bottles for milk tea. The project aim is to enhance UX/UI and functionalities with a young, modern outlook following the mobile-first approach."
        liveUrl="https://cuppe.com.au"
        sourceUrl="https://github.com/henry88lay/cuppe-custom-theme/"
        imageUrl="https://user-images.githubusercontent.com/44339322/98538572-1036a480-22df-11eb-818b-ee51f2393479.png"
      ></ProjectBlock>

      <ProjectBlock
        title="CASA by RubyForGood"
        description="CASA is an open-sourced Volunteer Management System for juvenile dependency court system in the US. The project is currnetly managed by RubyForGood. I'm a contributor on new features, testing, and bug fixing on both ends of the web app."
        liveUrl="https://casa-r4g-staging.herokuapp.com/"
        sourceUrl="https://github.com/rubyforgood/casa/pulls?q=is%3Apr+author%3Aerik-trantt"
        imageUrl="https://user-images.githubusercontent.com/44339322/98538547-090f9680-22df-11eb-9a7c-c203ddcb5527.png"
      ></ProjectBlock>

      <ProjectBlock
        title="CourseCreator"
        description="CourseCreator is a web app that help educators quickly transfer existing knowledge to digital format and share it with students. It is a final project for Le Wagon Coding Bootcamp."
        liveUrl="https://coursecreator.herokuapp.com/"
        sourceUrl="https://github.com/erik-trantt/CourseCreator"
        imageUrl="https://user-images.githubusercontent.com/44339322/98538568-0f9e0e00-22df-11eb-87a9-40a1715aa943.png"
        // imageUrl="https://via.placeholder.com/1200x800/9f392b/fff?text=CourseCreator+placeholder+image"
      ></ProjectBlock>
      </div>
    </Container>
  </section>

  )
}