import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import HeroSection from "../components/hero_section"
import ProjectSection from "../components/projects_section"
import ContactForm from "../components/contact_form"
import linkStyles from "../components/links.module.scss"

export default function Home() {
  // export default function Home({ data }) {
  const profileImg = "https://user-images.githubusercontent.com/44339322/98887487-2c5a6180-24ea-11eb-8717-b3e07a0fdb76.png";

  return (
    <Layout>
      <HeroSection>
        <img src={profileImg} alt="profile" 
          className="profile-img"
          css={css`
            height: auto;
            width: 25%;
            border-radius: 50%;
          `}
        />
        <h1 css={css` font-size: 1.5em; `}>Hi, I'm Erik Tran, a web developer.</h1>
        <Link className={[linkStyles.btn, linkStyles.btn__hero].join(' ')} to="#projects">See my projects</Link>
        <Link className={[linkStyles.links__inline].join(' ')} to="#contact-form">Contact me</Link>
      </HeroSection>
      <ProjectSection></ProjectSection>
      <ContactForm></ContactForm>
    </Layout>
  )
}

// export const query = graphql`
//   query {
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       totalCount
//       edges {
//         node {
//           id
//           frontmatter {
//             title
//             date(formatString: "DD MMMM, YYYY")
//           }
//           fields {
//             slug
//           }
//           excerpt
//         }
//       }
//     }
//   }
// `
